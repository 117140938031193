import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import slider from './slices/sliderSlice'
import plant from './slices/plantSlice'
import section from './slices/sectionSlice'
import equipment from './slices/equipmentSlice'
import material from  './slices/materialSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        slider,
        plant,
        section,
        equipment,
        material,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
