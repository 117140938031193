import fetch from "auth/FetchMasterInterceptor";

const masterService = {};

masterService.getReport = function (data) {
  return fetch({
    url: "production/data-report",
    method: "post",
    data: data,
  });
};

masterService.getDataReport = function (data) {
  return fetch({
    url: "production/summery-report",
    method: "post",
    data: data,
  });
};

masterService.exportSummeryExcel = function (data) {
  return fetch({
    url: "production/export-summery-report",
    method: "post",
    data: data,
  });
};

masterService.revFormatReport = function (data) {
  return fetch({
    url: "production/rev-format-report",
    method: "post",
    data: data,
  });
};

masterService.exportrevFormatExcel = function (data) {
  return fetch({
    url: "production/export-rev-format-report",
    method: "post",
    data: data,
  });
};

masterService.exportExcel = function (data) {
  return fetch({
    url: "production/export-data-report",
    method: "post",
    data: data,
  });
};

masterService.getPlan = function (data) {
  return fetch({
    url: "plan/list",
    method: "post",
    data: data,
  });
};

masterService.addPlan = function (data) {
  return fetch({
    url: "plan/add",
    method: "post",
    data: data,
  });
};

masterService.deletePlan = function (data) {
  return fetch({
    url: "plan/delete",
    method: "post",
    data: data,
  });
};
masterService.startPlan = function (data) {
  return fetch({
    url: "production/start",
    method: "post",
    data: data,
  });
};

masterService.getMaterial = function (data) {
  return fetch({
    url: "material/list",
    method: "post",
    data: data,
  });
};

masterService.addMaterial = function (data) {
  return fetch({
    url: "material/create",
    method: "post",
    data: data,
  });
};

masterService.updateMaterial = function (data) {
  return fetch({
    url: "material/update",
    method: "post",
    data: data,
  });
};

masterService.viewMaterial = function (data) {
  return fetch({
    url: "material/view",
    method: "post",
    data: data,
  });
};

masterService.deleteMaterial = function (data) {
  return fetch({
    url: "material/delete",
    method: "post",
    data: data,
  });
};

masterService.getProduction = function (data) {
  return fetch({
    url: "production/list",
    method: "post",
    data: data,
  });
};

masterService.deleteProduction = function (data) {
  return fetch({
    url: "production/delete",
    method: "post",
    data: data,
  });
};
masterService.abortPlan = function (data) {
  return fetch({
    url: "production/abort",
    method: "post",
    data: data,
  });
};

masterService.abortProcess = function (data) {
  return fetch({
    url: "production/delete",
    method: "post",
    data: data,
  });
};

masterService.getDepartment = function (data) {
  return fetch({
    url: "/employee/list",
    method: "post",
    data: data,
  });
};
masterService.addDepartment = function (data) {
  return fetch({
    url: "/department/create",
    method: "post",
    data: data,
  });
};
masterService.editDepartment = function (data) {
  return fetch({
    url: "/department/update",
    method: "post",
    data: data,
  });
};
masterService.deleteDepartment = function (data) {
  return fetch({
    url: "/department/delete",
    method: "post",
    data: data,
  });
};
masterService.getDesignation = function (data) {
  return fetch({
    url: "/designation/list",
    method: "post",
    data: data,
  });
};
masterService.addDesignation = function (data) {
  return fetch({
    url: "/designation/create",
    method: "post",
    data: data,
  });
};
masterService.editDesignation = function (data) {
  return fetch({
    url: "/designation/edit",
    method: "post",
    data: data,
  });
};
masterService.deleteDesignation = function (data) {
  return fetch({
    url: "/designation/delete",
    method: "post",
    data: data,
  });
};
masterService.getFrequency = function (data) {
  return fetch({
    url: "/frequency/list",
    method: "post",
    data: data,
  });
};
masterService.addFrequency = function (data) {
  return fetch({
    url: "/frequency/create",
    method: "post",
    data: data,
  });
};
masterService.editFrequency = function (data) {
  return fetch({
    url: "/frequency/edit",
    method: "post",
    data: data,
  });
};
masterService.deleteFrequency = function (data) {
  return fetch({
    url: "/frequency/delete",
    method: "post",
    data: data,
  });
};
masterService.employeeList = function (data) {
  return fetch({
    url: "/employee/list",
    method: "post",
    data: data,
  });
};

masterService.planList = function (data) {
  return fetch({
    url: "/plan/list",
    method: "post",
    data: data,
  });
};
masterService.planAdd = function (data) {
  return fetch({
    url: "/plan/add",
    method: "post",
    data: data,
  });
};
masterService.planEdit = function (data) {
  return fetch({
    url: "/plan/update",
    method: "post",
    data: data,
  });
};
masterService.planDelete = function (data) {
  return fetch({
    url: "/plan/delete",
    method: "post",
    data: data,
  });
};

masterService.shiftList = function (data) {
  return fetch({
    url: "/shift/list",
    method: "post",
    data: data,
  });
};
masterService.shiftAdd = function (data) {
  return fetch({
    url: "/shift/add",
    method: "post",
    data: data,
  });
};
masterService.shiftEdit = function (data) {
  return fetch({
    url: "/shift/update",
    method: "post",
    data: data,
  });
};
masterService.shiftDelete = function (data) {
  return fetch({
    url: "/shift/delete",
    method: "post",
    data: data,
  });
};

//meal
masterService.Addmeal = function (data) {
  return fetch({
    url: "/meal/add",
    method: "post",
    data: data,
  });
};

masterService.updateMeal = function (data) {
  return fetch({
    url: "/meal/update",
    method: "post",
    data: data,
  });
};

masterService.Listmeal = function (data) {
  return fetch({
    url: "/meal/list",
    method: "post",
    data: data,
  });
};

//menu services
masterService.menuAdd = function (data) {
  return fetch({
    url: "/menu/add",
    method: "post",
    data: data,
  });
};
masterService.menulist = function (data) {
  return fetch({
    url: "/menu/menuList",
    method: "post",
    data: data,
  });
};

//production services
masterService.Addproduction = function (data) {
  return fetch({
    url: "/production/add",
    method: "post",
    data: data,
  });
};

masterService.Listproduction = function (data) {
  return fetch({
    url: "web/screen/production",
    method: "post",
    data: data,
  });
};

masterService.exportproduction = function (data) {
  return fetch({
    url: "web/screen/production/export",
    method: "post",
    data: data,
  });
};
//station

masterService.stationList = function (data) {
  return fetch({
    url: "/station/list",
    method: "post",
    data: data,
  });
};

masterService.stationAdd = function (data) {
  return fetch({
    url: "/station/add",
    method: "post",
    data: data,
  });
};
masterService.stationEdit = function (data) {
  return fetch({
    url: "/station/update",
    method: "post",
    data: data,
  });
};

masterService.stationDelete = function (data) {
  return fetch({
    url: "/station/delete",
    method: "post",
    data: data,
  });
};

//userlist
masterService.userlist = function (data) {
  return fetch({
    url: "/user/list",
    method: "post",
    data: data,
  });
};

masterService.aaisgnStation = function (data) {
  return fetch({
    url: "station/assign",
    method: "post",
    data: data,
  });
};
export default masterService;
