import fetch from "auth/FetchMasterInterceptor";

const clientService = {};

clientService.getClient = function (data) {
  return fetch({
    url: "/client/list",
    method: "post",
    data: data,
  });
};
clientService.addClient = function (data) {
  return fetch({
    url: "/client/create",
    method: "post",
    data: data,
  });
};
clientService.editClient = function (data) {
  return fetch({
    url: "/client/edit",
    method: "post",
    data: data,
  });
};
clientService.deleteClient = function (data) {
  return fetch({
    url: "/client/delete",
    method: "post",
    data: data,
  });
};

clientService.getCompany = function (data) {
  return fetch({
    url: "/company/list",
    method: "post",
    data: data,
  });
};
clientService.addCompany = function (data) {
  return fetch({
    url: "/company/create",
    method: "post",
    data: data,
  });
};
clientService.editCompany = function (data) {
  return fetch({
    url: "/company/edit",
    method: "post",
    data: data,
  });
};
clientService.deleteCompany = function (data) {
  return fetch({
    url: "/company/delete",
    method: "post",
    data: data,
  });
};

clientService.addSection = function (data) {
  return fetch({
    url: "/section/create",
    method: "post",
    data: data,
  });
};
clientService.editSection = function (data) {
  return fetch({
    url: "/section/edit",
    method: "post",
    data: data,
  });
};
clientService.deleteSection = function (data) {
  return fetch({
    url: "/section/delete",
    method: "post",
    data: data,
  });
};
clientService.getSection = function (data) {
  return fetch({
    url: "/section/list",
    method: "post",
    data: data,
  });
};
clientService.getPlant = function (data) {
  return fetch({
    url: "/plant/list",
    method: "post",
    data: data,
  });
};
clientService.addPlant = function (data) {
  return fetch({
    url: "/plant/create",
    method: "post",
    data: data,
  });
};
clientService.editPlant = function (data) {
  return fetch({
    url: "/plant/edit",
    method: "post",
    data: data,
  });
};
clientService.deletePlant = function (data) {
  return fetch({
    url: "/plant/delete",
    method: "post",
    data: data,
  });
};
clientService.viewPlant = function (data) {
  return fetch({
    url: "/plant/view",
    method: "post",
    data: data,
  });
};

clientService.addEquipment = function (data) {
  return fetch({
    url: "/equipment/create",
    method: "post",
    data: data,
  });
};
clientService.editEquipment = function (data) {
  return fetch({
    url: "/equipment/edit",
    method: "post",
    data: data,
  });
};
clientService.deleteEquipment = function (data) {
  return fetch({
    url: "/equipment/delete",
    method: "post",
    data: data,
  });
};
clientService.getEquipment = function (data) {
  return fetch({
    url: "/equipment/list",
    method: "post",
    data: data,
  });
};

clientService.fatchGraphData = function (data) {
  return fetch({
    url: "/dashboard/data",
    method: "post",
    data: data,
  });
};

export default clientService;
