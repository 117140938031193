import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';



export const initialState = {
	loading: false,
	message: '',
	list:null,
	
}




export const materialListAll = createAsyncThunk('material/list',async (data, { rejectWithValue }) => {
	
	
    try {
		const response = await masterService.getMaterial(data)
		return response.data;
		
		
	} catch (err) {
		
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})



export const materialSlice = createSlice({
	name: 'material',
	initialState,
	reducers: {
			
		materialList: (state, action) => {
			state.loading = true
			state.list = action.payload
		},
		
	},
	extraReducers: (builder) => {
		builder
		.addCase(materialListAll.pending, (state) => {
		
				state.loading = false
			})
			.addCase(materialListAll.fulfilled, (state, action) => {
				state.loading = true
				state.list = action.payload
			})
			
			
	},
})

export const { 
	materialList
} = materialSlice.actions

export default materialSlice.reducer