import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

export const sliderSlice= createSlice({
    name:"slider",initialState:{
        value:0,
        length:3,
    },
    reducers:{
        nextSlide(state,action){
            state.value=action.payload > state.length ? 0 :action.payload;
        },
        prevSlide(state,action){
            state.value=action.payload<0 ? state.length:action.payload;
        },
        dotSlide(state,action){
            const slide=action.payload;
            // console.log("slidedot",slide);
            state.value=slide;
        }
    }
})

export const {nextSlide,prevSlide,dotSlide} =sliderSlice.actions; 
export default sliderSlice.reducer; 