import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/reset-password`,
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
  
    {
        key: 'dashboard.section',
        path: `${APP_PREFIX_PATH}/dashboards/section/:plantid`,
        component: React.lazy(() => import('views/app-views/dashboards/section')),
    },
    {
        key: 'dashboard.sectionadd',
        path: `${APP_PREFIX_PATH}/dashboards/section-add/:plantid`,
        component: React.lazy(() => import('views/app-views/dashboards/section/sectionadd')),
    },
    {
        key: 'dashboard.sectionedit',
        path: `${APP_PREFIX_PATH}/dashboards/section-edit/:plantid/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/section/sectionedit')),
    },
    {
        key: 'dashboard.subsection',
        path: `${APP_PREFIX_PATH}/dashboards/subsection/:plantid/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/section/subsection')),
    },
    {
        key: 'dashboard.plant',
        path: `${APP_PREFIX_PATH}/dashboards/plant`,
        component: React.lazy(() => import('views/app-views/dashboards/plant')),
    },
    {
        key: 'dashboard.client.plantadd',
        path: `${APP_PREFIX_PATH}/dashboards/plant-add`,
        component: React.lazy(() => import('views/app-views/dashboards/plant/plantadd')),
    },
    {
        key: 'dashboard.client.plantedit',
        path: `${APP_PREFIX_PATH}/dashboards/plant-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/plant/plantedit')),
    },
    {
        key: 'dashboard.section',
        path: `${APP_PREFIX_PATH}/dashboards/section`,
        component: React.lazy(() => import('views/app-views/dashboards/section')),
    },
    {
        key: 'dashboard.setplan',
        path: `${APP_PREFIX_PATH}/dashboards/viewplan`,
        component: React.lazy(() => import('views/app-views/dashboards/setupplan')),
    },
    {
        key: 'dashboard.plan',
        path: `${APP_PREFIX_PATH}/dashboards/plan`,
        component: React.lazy(() => import('views/app-views/dashboards/plan')),
    },
    {
        key: 'dashboard.equipment',
        path: `${APP_PREFIX_PATH}/dashboards/equipment`,
        component: React.lazy(() => import('views/app-views/dashboards/equipment')),
    },
    {
        key: 'dashboard.Production',
        path: `${APP_PREFIX_PATH}/dashboards/production/:plan_id`,
        component: React.lazy(() => import('views/app-views/dashboards/Production')),
    },
    {
        key: 'dashboard.plan',
        path: `${APP_PREFIX_PATH}/dashboards/product/:plan_id`,
        component: React.lazy(() => import('views/app-views/dashboards/plan')),
    },
    {
        key: 'dashboard.material',
        path: `${APP_PREFIX_PATH}/dashboards/material`,
        component: React.lazy(() => import('views/app-views/dashboards/material')),
    },
    {
        key: 'dashboard.client.addmaterial',
        path: `${APP_PREFIX_PATH}/dashboards/material-add`,
        component: React.lazy(() => import('views/app-views/dashboards/material/addmaterial')),
    },
    {
        key: 'dashboard.client.editmaterial',
        path: `${APP_PREFIX_PATH}/dashboards/material-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/material/editmaterial')),
    },
    
    {
        key: 'dashboard.tableReportData',
        path: `${APP_PREFIX_PATH}/dashboards/tableReportData`,
        component: React.lazy(() => import('views/app-views/dashboards/tableReportData')),
    },
    {
        key: 'dashboard.summery-report',
        path: `${APP_PREFIX_PATH}/dashboards/summery-report`,
        component: React.lazy(() => import('views/app-views/dashboards/testReport')),
    },
    {
        key: 'dashboard.final-report',
        path: `${APP_PREFIX_PATH}/dashboards/final-report`,
        component: React.lazy(() => import('views/app-views/dashboards/finalReport')),
    },
    {
        key: 'dashboard.machine',
        path: `${APP_PREFIX_PATH}/dashboards/machine`,
        component: React.lazy(() => import('views/app-views/dashboards/master/machine')),
    },
    {
        key: 'dashboard.sensor',
        path: `${APP_PREFIX_PATH}/dashboards/sensor`,
        component: React.lazy(() => import('views/app-views/dashboards/master/sensor')),
    },
    {
        key: 'dashboard.menu',
        path: `${APP_PREFIX_PATH}/dashboards/menu`,
        component: React.lazy(() => import('views/app-views/dashboards/master/menu')),
    },
    {
        key: 'dashboard.plan',
        path: `${APP_PREFIX_PATH}/dashboards/plan`,
        component: React.lazy(() => import('views/app-views/dashboards/master/plan')),
    },
    {
        key: 'dashboard.shift',
        path: `${APP_PREFIX_PATH}/dashboards/shift`,
        component: React.lazy(() => import('views/app-views/dashboards/master/shift')),
    },
    {
        key: 'dashboard.Employee',
        path: `${APP_PREFIX_PATH}/dashboards/employee_details`,
        component: React.lazy(() => import('views/app-views/dashboards/employee_details')),
    },
    {
        key: 'dashboard.productionsetup',
        path: `${APP_PREFIX_PATH}/dashboards/productionsetup`,
        component: React.lazy(() => import('views/app-views/dashboards/productionsetup')),
    },
   
    {
        key: 'dashboard.datatable',
        path: `${APP_PREFIX_PATH}/dashboards/datatable`,
        component: React.lazy(() => import('views/app-views/dashboards/datatable')),
    },
    {
        key: 'dashboard.user',
        path: `${APP_PREFIX_PATH}/dashboards/user`,
        component: React.lazy(() => import('views/app-views/dashboards/user')),
    },
    {
        key: 'dashboard.adduser',
        path: `${APP_PREFIX_PATH}/dashboards/user-add`,
        component: React.lazy(() => import('views/app-views/dashboards/user/useradd')),
    },
    {
        key: 'dashboard.useredit',
        path: `${APP_PREFIX_PATH}/dashboards/user-edit/:id`,
        component: React.lazy(() => import('views/app-views/dashboards/user/useredit')),
    },
    
    
]